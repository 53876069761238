import React from 'react';
import Skylight from 'react-skylight';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './HistoryEntry.module.css';
import CloseIcon from '../../assets/img/close-icon.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

function getSkylightStyles() {
  const width = typeof window !== 'undefined' ? window.innerWidth : 0;
  const height = typeof window !== 'undefined' ? window.innerHeight : 0;

  const mobile = width > 0 && width <= 600;
  const tablet = width > 0 && width <= 900;
  const landscape = height < width;

  if (mobile && !landscape) {
    return {
      height: 'auto',
      marginTop: 0,
      width: '100%',
      left: 0,
      marginLeft: 0,
      top: 75,
    };
  } else if (mobile && landscape) {
    return {
      height: 'auto',
      top: '50px',
      marginTop: '-25px',
      width: '60%',
      left: '50%',
      marginLeft: '-30%',
    };
  } else if (tablet) {
    return {
      height: 'auto',
    };
  }

  return {
    height: 'auto',
  };
}

const closeButtonStyle = {
  color: 'transparent',
  right: 0,
  top: 0,
  height: 40,
  width: 40,
  borderRadius: 20,
  textAlign: 'center',
  display: 'table-cell',
  verticalAlign: 'middle',
  background: '#fff url("' + CloseIcon + '") no-repeat center center / 60% 60%',
  zIndex: 100,
};

function HistoryEntry({ node }) {
  const { id, year, text, alttext, image, preview } = node;
  const { t } = useTranslation('labels');

  const skylightId = 'lightbox' + id;
  const that = typeof window !== 'undefined' ? window : this;

  const showSkylight = (e) => {
    that[skylightId].show();
    e.preventDefault();
  };

  return (
    <div className={styles.content}>
      <div className={styles.image}>
        <GatsbyImage image={getImage(preview)} alt={alttext} onClick={showSkylight} />
        <a className={styles.enlarge} onClick={showSkylight}>
          <span className="icon-enlarge" /> {t('enlargeImage')}
        </a>
        <h3>{year}</h3>
      </div>
      <div className={styles.text}>
        <p>{text}</p>
      </div>
      {typeof document !== 'undefined' && (
        <Skylight
          hideOnOverlayClicked
          ref={(ref) => {
            that[skylightId] = ref;
          }}
          transitionDuration={500}
          dialogStyles={getSkylightStyles()}
          closeButtonStyle={closeButtonStyle}
        >
          <GatsbyImage image={getImage(image)} alt={alttext} className={styles.enlargedImage} />
        </Skylight>
      )}
    </div>
  );
}

export default HistoryEntry;
