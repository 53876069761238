import * as React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '../../components/Common/PageTitle';
import HistoryEntry from '../../components/Geschichte/HistoryEntry';

const GeschichtePage = function ({ data, location, pageContext }) {
  const entries = data.geschichte.edges;
  const { siteMetadata } = data.site;
  const { title, icon, metadata, opengraph } = data.page;

  return (
    <DefaultLayout>
      <div className="wrap">
        <PageTitle title={title} icon={icon} />
        {entries.map(({ node }) => (
          <HistoryEntry node={node} key={node.id} />
        ))}
        <Metadata
          data={metadata}
          opengraphData={opengraph}
          defaultTitle={siteMetadata.title}
          canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
          path={location.pathname}
        />
      </div>
    </DefaultLayout>
  );
};

export default GeschichtePage;

export const GeschichtePageQuery = graphql`
  query HistoryQuery($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: cockpitRegionGeschichte(lang: { eq: "en" }) {
      title
      icon
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    geschichte: allCockpitGeschichte(filter: { lang: { eq: $language } }) {
      edges {
        node {
          id
          year
          title
          text
          alttext
          preview: image {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 300
                aspectRatio: 3.5
                transformOptions: { cropFocus: ATTENTION }
                placeholder: TRACED_SVG
              )
            }
          }
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 900, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`;
